*{margin: 0px; padding: 0px}

#contactSec {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 40px;
    margin: -1px;
}

.hdrCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hdr {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    margin: 30px 15px 20px 15px;
}

.subtxt {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin: 0px 15px;
}

.openIconCont {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
}

.openIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 30px 0px;
    min-width: 282px;
}

.iconBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 20px;
    width: fit-content;
}

.icon {
    pointer-events: none;
}

.servHdr {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin: 15px 0px;
}

@media only screen and (min-width: 526px) {
    .openIconCont {
        margin-bottom: 52px;
    }
}

@media only screen and (min-width: 1080px) {
    .openIconCont {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .openIcon {
        margin: 0px 30px 0px 30px;
    }
}

@media only screen and (min-width: 1360px) {
    .hdr {
        margin: 52px 15px 20px 15px;
    }

    .openIconCont {
        margin-top: 52px;
    }
}

@media only screen and (min-width: 1420px) {
    .hdr {
        font-size: 32px;
    }
}