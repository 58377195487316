*{margin: 0px; padding: 0px}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

#hmLight {
    display: inline-block;
    border-radius: 5px;
    margin: 18px 18px 0px 0px;
}

#hmDark {
    display: inline-block;
    border-radius: 5px;
    margin: 18px 18px 0px 0px;
}

#hmLight:hover {
    border: 1px solid rgb(29, 116, 203);
    background-color: rgb(242, 242, 243);
    transition: 0.2s ease-in-out;
}

#hmDark:hover {
    border: 1px solid rgb(29, 116, 203);
    background-color: rgb(33, 49, 80);
    transition: 0.2s ease-in-out;
}

#hmCont {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
}

#hmLinkCont {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0%, 0%, 0%, 0.3);
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

#hmLinks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 280px;
    height: 100vh;
}

#hmLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

#hmRoutes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
}

#hmRoutes>a {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-decoration: none;
    font-size: 18px;
    margin: 18px 25px;
}

.hmLink:hover {
    transform: translateY(-3px);
    text-decoration: underline !important;
    transition: 0.4s ease-out;
}

#hmContactBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 12px 10px;
    color: whitesmoke;
    background-color: rgb(25, 118, 210);
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.5);
}

#hmContactBtn:hover {
    background-color: rgb(13, 71, 161);
    transition: 0.2s ease-in-out;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#navLogo {
    margin: 18px 0px 0px 18px;
}

#navLinks {
    display: none;
}

#ftrLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
}

#coprCont {
    display: flex;
    justify-content: center;
    align-items: center;
}

#copr {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 28px;
}

#privPolicyCont {
    margin: 5px 15px 0px 15px;
    padding-bottom: 30px;
    text-align: center;
}

#privPolicyDesc {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

@media only screen and (min-width: 740px) {
    #mobileOnly {
        display: none;
    }

    #navLogo {
        margin: 40px 0px 5px 40px;
    }
    
    #navLinks {
        display: inline-block;
        margin: 40px 40px 5px 0px;
    }

    #navLinks>a {
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        text-decoration: none;
        font-size: 18px;
        margin: 12px;
    }

    .navLink:hover {
        transform: translateY(-3px);
        text-decoration: underline !important;
        transition: 0.4s ease-out;
    }

    #contactBtn {
        display: inline-block;
        border-radius: 5px;
        padding: 12px 10px;
        color: whitesmoke;
        background-color: rgb(25, 118, 210);
        box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.5);
    }

    #contactBtn:hover {
        background-color: rgb(13, 71, 161);
        transition: 0.2s ease-in-out;
    }
}

@media only screen and (min-width: 1080px) {
    #navLogo {
        margin: 40px 0px 5px 6vw;
    }
    
    #navLinks {
        margin: 40px 6vw 5px 0px;
    }
}

@media only screen and (min-width: 1360px) {
    #navLogo {
        margin: 40px 0px 5px 12vw;
    }
    
    #navLinks {
        margin: 40px 12vw 5px 0px;
    }

    #privPolicyCont {
        margin: 5px 200px 0px 200px;
    }
}

@media only screen and (min-width: 1620px) {
    #navLogo {
        margin: 40px 0px 5px 18vw;
    }
    
    #navLinks {
        margin: 40px 18vw 5px 0px;
    }
}