*{margin: 0px; padding: 0px}

.hdrCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hdr {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    margin: 30px 15px 20px 15px;
}

.subtxt {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin: 0px 15px;
}

#toolSecCont {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 35px;
}

.boxBorder {
    border-radius: 10px;
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.5);
    padding: 25px;
    margin: 10px 15px;
}

.altIconBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    background-color: rgb(25, 118, 210);
    width: fit-content;
}

.icon {
    pointer-events: none;
}

.servHdr {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin: 15px 0px;
}

.toolSubtxt {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

@media only screen and (min-width: 710px) {
    #toolSecCont {
        grid-template-columns: repeat(2, 1fr);
    }

    .boxBorder {
        min-height: 275px;
    }
}

@media only screen and (min-width: 880px) {
    .boxBorder {
        min-height: 215px;
    }
}

@media only screen and (min-width: 1080px) {
    #toolSecCont {
        grid-template-columns: repeat(3, 1fr);
    }

    .boxBorder {
        min-height: 250px;
    }
}

@media only screen and (min-width: 1360px) {
    .hdr {
        margin: 52px 15px 20px 15px;
    }

    .boxBorder {
        min-height: 220px;
    }
}

@media only screen and (min-width: 1420px) {
    .hdr {
        font-size: 32px;
    }

    #toolSecCont {
        margin: 52px 0px;
    }
}

@media only screen and (min-width: 1620px) {
    #toolSecCont {
        margin: 52px 200px;
    }

    .boxBorder {
        min-height: 250px;
    }
}

@media only screen and (min-width: 1720px) {
    .boxBorder {
        min-height: 220px;
    }
}