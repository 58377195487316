*{margin: 0px; padding: 0px}

#slide {
    border-radius: 10px;
    box-shadow: 0 0 30px;
    width: 90vw;
    height: auto;
}

#icon {
    pointer-events: none;
}

@media only screen and (min-width: 550px) {
    #slide {
        width: 511px;
        height: auto;
    }
}