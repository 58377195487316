*{margin: 0px; padding: 0px}

.switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
    bottom: 8px;
    margin: 10px;
    z-index: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid black;
    border-radius: 20%/50%;
    transition: 0.4s ease-out;
}

.slider:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    left: 0px;
    bottom: -2px;
    background-color: whitesmoke;
    box-shadow: 3px 3px 5px rgb(0, 0, 0, 0.4);
    border: 1px solid black;
    border-radius: 50%;
    transition: 0.4s ease-out;
}

input:checked + .slider:before {
    transform: translateX(32px);
}