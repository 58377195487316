*{margin: 0px; padding: 0px}

#flexWidgCont {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 40px 0px;
    margin: -1px;
}

#slideWidgCont {
    display: flex;
    justify-content: center;
    align-items: center;
}

#sloganWidgCont {
    margin: 15px 0px 35px 15px;
}

svg {
    display: block;
}

@media only screen and (min-width: 526px) {
    #flexWidgCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .viewBtn {
        display: inline-block;
    }
}

@media only screen and (min-width: 710px) {
    #sloganWidgCont {
        margin: 15px 0px 80px 15px;
    }
}

@media only screen and (min-width: 1080px) {
    #flexWidgCont {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #sloganWidgCont {
        margin-left: 6vw;
    }

    #slideWidgCont {
        margin-right: 6vw;
    }
}

@media only screen and (min-width: 1360px) {
    #sloganWidgCont {
        padding-top: 60px;
        margin-left: 12vw;
    }

    #slideWidgCont {
        margin-right: 12vw;
    }
}

@media only screen and (min-width: 1620px) {
    #sloganWidgCont {
        margin-left: 18vw;
    }

    #slideWidgCont {
        margin-right: 18vw;
    }
}