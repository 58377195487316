*{margin: 0px; padding: 0px}

#privPolicy {
    margin: -1px;
}

.privHdr {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    padding: 30px 15px 20px 15px;
}

#thrdPtyAct {
    list-style: none;
    margin: 25px 15px
}

@media only screen and (min-width: 1080px) {
    #privPolicy {
        padding: 0px 6vw;
    }
}

@media only screen and (min-width: 1360px) {
    #privPolicy {
        padding: 0px 12vw;
    }
}

@media only screen and (min-width: 1620px) {
    #privPolicy {
        padding: 0px 18vw;
    }
}