*{margin: 0px; padding: 0px}

.sloganTxt {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 36px;
}

#sloganAnim {
    font-family: 'M PLUS Code Latin', sans-serif;
    color: rgb(25, 118, 210);
    text-decoration: 4px solid underline rgb(78, 201, 176, 0.4);
    font-size: 36px;
}

#animIP {
    font-family: 'M PLUS Code Latin', sans-serif;
    color: rgb(25, 118, 210);
}

#sloganSubtxt {
    margin: 20px 15px 20px 0px;
}

.altSubtxt {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.viewBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 12px 10px;
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.5);
    margin: 20px 15px 20px 0px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    cursor: pointer;
}

#viewAppsBtn {
    background-color: rgb(25, 118, 210);
    color: whitesmoke;
}

#viewAppsBtn:hover {
    background-color: rgb(13, 71, 161);
    transition: 0.2s ease-in-out;
}

#viewSitesBtnLight {
    color: rgb(25, 118, 210)
}

#viewSitesBtnDark {
    color: rgb(25, 118, 210)
}

#viewSitesBtnLight:hover {
    border: 1px solid rgb(29, 116, 203);
    background-color: rgb(231, 231, 232);
    transition: 0.2s ease-in-out;
}

#viewSitesBtnDark:hover {
    border: 1px solid rgb(29, 116, 203);
    background-color: rgb(33, 49, 80);
    transition: 0.2s ease-in-out;
}

@media only screen and (min-width: 1420px) {
    .sloganTxt {
        font-size: 50px;
    }

    #sloganAnim {
        font-size: 50px;
    }
}